.app {
    padding: 0px;

    #app-bar {
      .icon-button {
        padding-right: 0;

        #user-name {
          font-weight: 400;
          font-size: 1.25rem;
          padding-left: 5px;
        }
      }
    }

    .start {
      padding: 20px;
      margin: 15px;
      text-align: center;
    }

    .user {
      padding: 20px;
      margin: 15px;

      .MuiFormControl-root {
        width: 100%;
      }

      .slider {
        width: 80%;
        margin: 2% 10%
      }

      #edit-user-button, #add-user-button {
        padding: 0 12px 0 12px;

        svg {
          height: 18px;
        }
      }
    }

    .macros {
      padding: 20px;
      margin: 15px;

      .MuiFormControl-root {
        width: 100%;
      }

      h3 {margin-top: 0;}
    }

    .center-align {
      align-items: center;
      text-align: center;
    }

    .right-align {
      align-items: right;
      text-align: right;
    }

    .boldText {
      font-weight: 700;
    }
    .capitalize {
      text-transform: capitalize
    }
}

